<template>
  <Card>
    <div class="row center">
      <h5>Passwort zurücksetzen</h5>
      <label v-if="!busy">Bitte legen Sie nun ein neues Passwort fest</label>
    </div>

    <div v-if="busy" class="center">
      Ihr neues Passwort wird gespeichert...<br><br>
      Bitte haben Sie einen Moment Geduld.
    </div>
    <form v-else @submit.prevent="resetPassword" class="w-100">
      <div class="row mt-1">
        <InputField required v-model="password" id="password" label="Passwort" type="password" icon="lock_outline" />
      </div>

      <div class="flex-row flex-center">
        <button :disabled="busy" type="submit" class="btn waves-effect waves-light">Neues Passwort festlegen</button>
      </div>
    </form>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";
import InputField from "@/components/InputField.vue";
import { actions, http, routes, pages } from "@/constants.js";

export default {
  name: "PasswordResetForm",
  data() {
    return {
      password: ""
    };
  },
  computed: {
    busy() {
      return this.$store.state.busy;
    },
    token() {
      return this.$route.params.token;
    }
  },
  methods: {
    async resetPassword() {
      const result = await this.$store.dispatch(actions.API_REQUEST, {
        route: routes.ACCOUNT.RESET_PASSWORD.RESET,
        method: http.POST,
        body: {
          token: this.token,
          password: this.password
        }
      });

      if (result === null) return;

      this.$toast.success("Ihr Passwort wurde erfolgreich geändert. Sie können sich nun einloggen.");
      this.$router.push(pages.LOGIN);
    }
  },
  components: {
    Card,
    InputField
  }
};
</script>

<style module type="scss">

</style>
